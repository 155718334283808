import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

class Footer extends React.Component {
  render() {
    return (
      <div className="border-t border-gray-200 flex flex-col items-center">
        <div className="mt-10">
          Bring your business to the next level with us!
        </div>
        <Link
          to="/contact/"
          className="mt-10 rounded-full bg-transparent hover:font-semibold py-2 px-4 border border-gray-400 rounded"
        >
          {/* <button></button> */}
          Get in Touch
          <FontAwesomeIcon className="ml-2 arrow" icon={faArrowRight} />
        </Link>
        <div className="bg-black px-4 py-8 md:px-8 mt-10 w-full md:w-4/5 flex items-center justify-between">
          <div className="text-gray-400">
            From Bali with{" "}
            <span role="img" aria-label="from bali with coffee">
              ☕
            </span>
          </div>
          <div className="text-gray-400">© 2019 Digitify</div>
        </div>
      </div>
    )
  }
}

export default Footer
