import { Link } from "gatsby"
import React from "react"

const Item = ({ props }) => {
  return (
    <Link
      className={`m-2 hover:font-bold text-xs ${props.active &&
        "border-b border-b-2 border-teal-500"}`}
      to={props.to}
    >
      {props.text}
    </Link>
  )
}

class Header extends React.Component {
  render () {
    const items = [
      { id: "home", text: "Home", to: "/" },
      { id: "expertise", text: "Expertise", to: "/expertise/" },
      { id: "contact", text: "Contact", to: "/contact/" },
    ]
    items.find(x => x.id === this.props.page).active = true
    return (
      <div className="flex justify-between p-4">
        <Link to="/" className="font-bold text-teal-600">
          <img
            src={"/digitify-logo.svg"}
            style={{ width: "100px", height: "100px", marginTop: "-20px" }}
          ></img>
        </Link>
        <div>
          {items.map((item, idx) => (
            <Item props={item} key={idx} />
          ))}
        </div>
      </div>
    )
  }
}

export default Header
